// Body Materials for MediaCoach's BackOffice project
@import 'styles/config';

@mixin m-body() {
  font-family: var(--t-font-family--primary);
  font-size: $c-font-size;
  margin: $c-space--none;
}

.m-body {
  @include m-body();
}

@mixin m-body__accent() {
  @include m-body();
  font-family: $c-font-family__accent;

  &.m-body__accent--small {
    font-size: $c-font-size--xs;
  }

  &.m-body__accent--tiny {
    font-size: $c-font-size--xs;
  }
}

.m-body__accent {
  @include m-body__accent();
}

@mixin m-body__mention() {
  @include m-body();
  font-family: $c-font-family__primary--italic;
}

.m-body__mention {
  @include m-body__mention();
}
