@import './styles/_config';
@import "styles/material/main";


html {
  font-family: var(--t-font-family--primary);
  color: $c-color__neutral--grey--70;
  background-color: $c-color__neutral--grey--05;
  font-size: 0.875em;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  min-height: 100%;
}

.u-margin-bottom--lg {
  margin-bottom: 16px;
}

ul {
  margin: 0;
  padding: 0;
}

.overflow-hidden {
  overflow: hidden;
  &--xs {
    overflow: hidden;
    @include t-from(sm) {
      overflow: initial;
    }
  }
}

.text-center {
  text-align: center;
}

[class*=" u-icon"]::before {
  font-size: 1.2em;
}

.u-font-size--l {
  &, &::before {
    font-size: 1.3em !important;
  }
}

.u-font-size--xl {
  &, &::before {
    font-size: 2em !important;
  }
}

.u-opacity--30 {
  opacity: 0.3 !important;
}

.u-vertical-centered {
  vertical-align: middle;
}

.u-separator {
  height: 1px;
  width: 100%;
  margin: $c-space--md 0;
  background-color: #f0f0f0;
}

